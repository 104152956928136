// extracted by mini-css-extract-plugin
export var alignLeft = "t_qf d_bG";
export var alignCenter = "t_bP d_bD";
export var alignRight = "t_qg d_bH";
export var textAlignLeft = "t_rK";
export var textAlignCenter = "t_rL";
export var textAlignRight = "t_rM";
export var hoursInnerWrapperAlt = "t_rN d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "t_rP d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "t_rQ d_dw";
export var titleMargin = "t_rR d_cw";
export var hoursInnerInnerWrapper = "t_rS d_cz";