// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_rT d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_rV d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_rW d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_rX d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_rY d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_rZ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_r0 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_r1 x_r1";
export var heroExceptionNormal = "v_r2 x_r2";
export var heroExceptionLarge = "v_r3 x_r3";
export var Title1Small = "v_r4 x_r4 x_sd x_sf";
export var Title1Normal = "v_r5 x_r5 x_sd x_sg";
export var Title1Large = "v_r6 x_r6 x_sd x_sh";
export var BodySmall = "v_r7 x_r7 x_sd x_sx";
export var BodyNormal = "v_r8 x_r8 x_sd x_sy";
export var BodyLarge = "v_r9 x_r9 x_sd x_sz";